import { useModalContext } from 'src/design-system'
import cn from 'classnames'
import React from 'react'
import { closeModal } from '../../utils/close-modal'
import { SkillModalProps, SKILL_MODAL_ID } from './utils'
import { store } from 'store/index'
import { trackEvent } from '../../services/event-tracker'

export type SkillModalButtonProps = {
  children: React.ReactNode
  className?: string
  source?: string
} & SkillModalProps

export const SkillModalButton: React.VFC<SkillModalButtonProps> = (props) => {
  const { children, className, source, ...restProps } = props

  const currentUser = store.nonNullCurrentUser
  const hasActiveSubscription = !!currentUser.org?.activeSubscription

  const { openModal } = useModalContext()

  const onClick = () => {
    closeModal()
    trackEvent('$track_skill_modal_opened', { source })

    if (hasActiveSubscription) {
      openModal(SKILL_MODAL_ID, { ...restProps, source })
    } else {
      window.location.href = currentUser.isAdmin
        ? `/orgs/${currentUser.org?.slug}/billing/new`
        : '/'
    }
  }

  return (
    <div
      className={cn(className, 'cursor-pointer')}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {children}
    </div>
  )
}

export const SkillModalButtonWithProvider: React.VFC<SkillModalButtonProps> = ({
  children,
  ...restProps
}) => {
  return <SkillModalButton {...restProps}>{children}</SkillModalButton>
}
